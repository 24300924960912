.image-carousel-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.image-carousel-modal-close-icon {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  color: white;
  z-index: 1001;
}

.image-carousel-modal-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.image-carousel-modal-full-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
