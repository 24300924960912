.orange-button {
  background-color: var(--orange-color);
  border: none;
  color: var(--black-color);
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
}

.custom-radio-group {
  display: flex;
  gap: 20px;
}

.custom-radio {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  padding-left: 35px;
  display: block;
  line-height: 25px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50%;
}

.custom-radio:hover input ~ .checkmark {
  background-color: #f2f2f2;
}

.custom-radio input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
}

.auth-continue-button {
  background-color: var(--light-orange-color);
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  margin-top: 20px;
  font-weight: bold;
}
