.service-details-carousel-item {
  height: 30rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.service-details-carousel-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.service-details-description {
  margin: 20px 0;
  font-weight: 500;
}

.package-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

.service-details-footer {
  margin-top: 20px;
  text-align: center;
}

.service-details-divider {
  height: 10px;
  width: 100%;
  margin-bottom: 30px;
  background-color: #eeeeee;
}

.customized-message {
  text-align: center;
  margin: 20px 0;
}

.customized-message strong {
  display: block;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.send-message-button {
  background-color: var(--light-orange-color);
  color: black;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 20rem;
}

.filter-chip {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 20px;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.filter-chip.selected {
  background-color: var(--light-orange-color);
  font-weight: 500;
}

.tax-icon {
  width: 16px;
  margin-right: 8px;
  vertical-align: middle;
}
