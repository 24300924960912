.service-card {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
}

.service-image {
  width: 18rem;
  height: 11rem;
  border-radius: 10px;
  margin-right: 2.2rem;
}

.service-info {
  flex: 1;
}

.chevron-icon {
  float: right;
  margin-left: 10px;
}

.service-info h3 {
  margin: 0;
}

.service-info p {
  margin: 8px 0;
}

.price-text {
  color: #717171;
}

.price {
  color: black;
  font-weight: bold;
}
