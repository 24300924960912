.section-title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 20px 0;
  font-size: 1.2rem;
}

.see-all {
  color: var(--orange-color);
  cursor: pointer;
}
