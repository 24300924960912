.reservation-container {
  box-shadow: 0 4px 15px 2px #dedede;
}

.reservation-header {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.reservation-chevron-icon {
  margin-right: 1rem;
}

.reservation-title {
  margin: 0;
  font-weight: bold;
  font-size: 1.4rem;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
  background-color: white;
  border-bottom: 2px solid #e0e0e0;
  box-shadow: 0 4px 15px 2px #dedede;
}

.tab {
  padding: 15px 20px;
  cursor: pointer;
  color: #717171;
  width: 100%;
  text-align: center;
  background-color: white;
}

.tab.active {
  color: black;
  font-weight: bold;
  border-bottom: 2px solid black;
}

.tab-content {
  margin-top: 20px;
}

.reservation-card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
