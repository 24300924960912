.coupon-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.coupon-modal-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  position: relative;
  text-align: center;
}

.coupon-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.coupon-modal-message {
  margin-top: 20px;
  font-size: 1rem;
}
