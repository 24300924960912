.home-middle-carousel {
  margin: 20px 0;
}

.home-middle-carousel .carousel-middle-item img {
  height: 15rem;
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}

/* .carousel-middle-item-large img {
  height: 15rem;
} */

.react-multi-carousel-dot button {
  border: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.react-multi-carousel-dot--active button {
  background-color: var(--light-orange-color);
  border: none;
  width: 12px;
  height: 8px;
  border-radius: 30%;
}
