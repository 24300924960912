.package-card {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.package-card-header {
  display: flex;
  align-items: center;
}

.package-card-title {
  font-weight: bold;
  margin-right: 10px;
}

.package-card-divider-vertical {
  width: 1px;
  height: 100%;
  background-color: white;
  margin: 0 10px;
}

.package-card-subtitle {
  font-weight: normal;
}

.package-card-divider-horizontal {
  height: 1px;
  background-color: white;
  margin: 10px 0;
}

.package-card-details {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.package-card-detail {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.detail-icon {
  margin-right: 15px;
}

.detail-icon.tick {
  color: var(--light-orange-color);
}

.detail-icon.cross {
  color: var(--light-orange-color);
}
.package-card-toggle {
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 10px;
}

.package-card-price {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.package-card-button {
  background-color: var(--light-orange-color);
  font-weight: bold;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.package-card-prices {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.package-card-regular-price {
  color: #bfbfbf;
  text-decoration: line-through;
  margin-right: 10px;
}

.package-card-sale-price {
  font-size: 1.2rem;
  font-weight: bold;
}
