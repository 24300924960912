.summary-container {
  display: flex;
  gap: 30px;
  margin-top: 40px;
  align-items: stretch;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.right {
  flex: 3;
}

.header {
  font-weight: bold;
  margin-bottom: 2rem;
}

.time-slot {
  cursor: pointer;
}

.time-slot.selected {
  border: 2px solid black;
}

.time-slot-card {
  margin-bottom: 20px;
}

.time-slot-header {
  display: flex;
  margin-bottom: 0.2rem;
}

.time-slot-header p {
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 4px;
}

.slot-period {
  font-weight: bold;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.time-slots {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.time-slot {
  border: 1px solid var(--divider-color);
  padding: 10px;
  border-radius: 5px;
  margin-right: 5px;
  min-width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.shoot-info-card {
  border-radius: 5px;
  padding: 15px;
  border: 1px solid var(--divider-color);
}

.shoot-info-card p:last-child {
  margin-bottom: 0;
}

.shoot-info-card p:first-child {
  margin-bottom: 10px;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  flex-grow: 1;
}

.react-calendar__viewContainer {
  height: 100%;
}

.react-calendar__tile {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__tile {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
}

.selected-date {
  border: 2px solid black !important;
  background: transparent !important;
  color: black !important;
}

.disabled-date {
  text-decoration: line-through;
}

.shoot-info-card-content p {
  display: flex;
  justify-content: space-between;
}

.time-slot.disabled-date {
  cursor: not-allowed;
}

.continue-button {
  background-color: var(--light-orange-color);
  font-weight: bold;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 30rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.continue-button:disabled {
  background-color: #dedede;
  color: white;
}
