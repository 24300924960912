.shoot-details-card {
  border-radius: 5px;
  padding: 15px;
  border: 1px solid var(--divider-color);
  background-color: white;
  margin-top: 20px;
}

.shoot-details-card .edit-text {
  font-weight: bold;
  color: var(--orange-color);
}

.shoot-details-card .divider {
  height: 8px;
  background-color: var(--divider-color);
  margin: 10px 0;
}

.shoot-details-card .details {
  display: flex;
  flex-direction: column;
}

.shoot-details-card .detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.shoot-details-card .label {
  font-weight: bold;
  color: black;
}

.shoot-details-card .value {
  font-weight: normal;
}

.shoot-details-card .detail:last-child {
  flex-direction: row;
}

.location-detail {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
}
