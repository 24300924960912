.summary-container {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.left {
  flex: 1.2;
}

.right {
  flex: 3;
}

.summary-package-card {
  border-radius: 5px;
  padding: 20px;
  border: 1px solid var(--divider-color);
}

.summary-package-card .title {
  font-weight: bold;
}

.summary-package-card .price {
  font-weight: bold;
}

.summary-package-card .divider {
  height: 1px;
  background-color: var(--divider-color);
  margin: 10px 0;
}

.summary-package-card .feature {
  display: flex;
  align-items: center;
}

.summary-package-card .feature .icon {
  margin-right: 10px;
}

.info-card {
  border-radius: 5px;
  padding: 15px;
  border: 1px solid var(--divider-color);
}

.info-card .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-card .title {
  font-weight: bold;
}

.info-card .edit-text {
  font-weight: bold;
  color: var(--orange-color);
}

.info-card p {
  margin: 0;
}

.price-details-text {
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.price-details-total {
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.coupon-input-group {
  margin-bottom: 20px;
  margin-right: 10px;
}

.apply-button {
  background-color: var(--light-orange-color);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.apply-button:hover {
  background-color: var(--light-orange-color);
  color: black;
}

.checkout-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.chekout-button {
  background-color: var(--light-orange-color);
  font-weight: bold;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.remove-coupon-button {
  background-color: red;
  color: white;
}

.remove-coupon-button:hover {
  background-color: darkred;
  color: white;
}
