.page-header-container {
  box-shadow: 0 4px 15px 2px #dedede;
  cursor: pointer;
}

.page-header {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.page-chevron-icon {
  margin-right: 1rem;
}

.page-title {
  margin: 0;
  font-weight: bold;
  font-size: 1.4rem;
}
