.portfolio-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-modal-content {
  background: white;
  border-radius: 20px;
  padding: 20px;
  width: 400px;
  position: relative;
}

.portfolio-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 1.2rem;
  position: relative;
}

.portfolio-inclusion-container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.portfolio-close-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.portfolio-modal-divider {
  border: 1px solid grey;
  margin: 10px 0;
}

.portfolio-modal-description {
  margin-bottom: 20px;
  font-size: 0.9rem;
}

.portfolio-modal-close-button {
  width: 100%;
  padding: 10px;
  background: var(--light-orange-color);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
