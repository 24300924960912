.upload-container {
  border: 2px solid #b0b0b0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-top: 40px;
  padding: 160px 0;
}

.upload-icon {
  height: 100px;
  margin-bottom: 10px;
}

.upload-text {
  text-align: center;
}

.browse-label {
  color: var(--orange-color);
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  padding: 0 3px;
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  margin-top: 30px;
}

.uploaded-image {
  height: 7rem;
  width: 7rem;
  object-fit: cover;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  color: black;
  border: none;
  border-radius: 50%;
  width: 5px;
  height: 20px;
  justify-content: center;
  padding: 13px;
  margin: 0 1px;
  font-size: 30px;
  cursor: pointer;
}
