.home-empty-state {
  text-align: center;
  padding: 50px;
  color: #555;
}

.home-empty-state h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.home-empty-state p {
  font-size: 18px;
  color: #777;
}
