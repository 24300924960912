.cancel-booking-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
}

.cancel-booking-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.cancel-booking-modal-header h6 {
  font-weight: bold;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.cancel-booking-modal-close-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.cancel-booking-modal-body {
  margin-bottom: 20px;
}

.cancel-booking-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.cancel-booking-modal-cancellation-policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.cancel-booking-modal-button {
  width: 100%;
  padding: 10px;
  background: var(--light-orange-color);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-cancellation-title {
  font-weight: bold;
  margin: 20px 0;
}

.refund-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #717171;
  margin-top: 10px;
  font-size: 14px;
}

.refund-icon {
  margin-right: 8px;
}
