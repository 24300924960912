.footer-container {
  background-color: black;
}
.footer-download-container {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  padding: 1.5rem 3rem;
}
.download-text {
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.button-container {
  display: flex;
}

.footer-container button {
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;
  border: 1.5px solid white;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
  margin-right: 1rem;
}

.button-text-start {
  font-weight: 100;
  font-size: 10px;
}

.footer-image {
  height: 2rem;
}

.button-text-container {
  text-align: start;
  margin-left: 0.5rem;
}

.footer-links {
  display: flex;
  justify-content: space-evenly;
  padding: 1.5rem;
}

.footer-links a {
  color: white;
  text-decoration: underline;
  font-weight: 600;
  margin-right: 1rem;
}

.footer-links a:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .footer-container {
    flex-direction: column;
  }

  .download-text {
    margin-bottom: 9px;
  }

  .button {
    padding: 0.2rem 1rem;
  }

  .footer-image {
    height: 1.5rem;
  }

  .faq-container {
    padding: 20px 0 !important;
    display: flex;
    justify-content: space-between;
    margin-right: 0px !important;
  }

  .faq-title {
    font-size: 2em !important;
  }

  .faq-image {
    width: 5rem !important;
  }
}

@media screen and (max-width: 420px) {
  .button-container {
    flex-direction: column;
  }

  .home-separator-image {
    width: 70px !important;
  }

  .google-play-button {
    margin-bottom: 12px !important;
  }

  .footer-download-container {
    display: block;
  }
}
