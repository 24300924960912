.home-carousel-item {
  position: relative;
  height: 15rem;
  margin-right: 20px;
}

.home-carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home-carousel-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  border-radius: 0 0 10px 10px;
}

.home-carousel-item-name {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 1;
}
