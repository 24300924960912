.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.not-found-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.not-found-message {
  font-size: 1.5rem;
}

.not-found-button {
  background-color: var(--orange-color);
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
