.map-container {
  margin-bottom: 20px;
}

.address-button {
  background-color: var(--light-orange-color);
  font-weight: bold;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.custom-chip-button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.custom-chip-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  border: 1px solid black;
  border-radius: 30px;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
}

.custom-chip-button.selected {
  background-color: #ffdbbb;
}

.custom-chip-button svg {
  margin-right: 5px;
}
