.left-home-drawer {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 50rem;
  margin-right: 4.5rem;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1001;
}

.left-home-drawer.open {
  transform: translateX(0);
}

.drawer-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.close-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.drawer-content {
  padding: 1rem 4.5rem 1rem 22rem;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 1.2;
}

.location-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
}

.location-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.location-text {
  display: flex;
  flex-direction: column;
}

.get-location {
  font-weight: 500;
}

.using-gps {
  font-size: 0.875rem;
  color: #666;
}

.location-results {
  margin-top: 1rem;
}

.location-item {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.location-item + .location-item {
  border-top: 1px solid #eee;
}

.location-item span {
  display: block;
  margin-bottom: 0.5rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
