.contact-us-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-modal-content {
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin: 0 20rem;
  position: relative;
}

.contact-us-modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.contact-us-modal-close-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.contact-us-radio-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
}

.contact-us-radio-group label {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.contact-us-radio-group input[type="radio"] {
  margin-right: 10px;
  transform: scale(1.5);
}

.contact-us-radio-group input[type="radio"]:checked {
  accent-color: black;
}

.contact-us-modal-textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.contact-us-modal-submit-button {
  width: 100%;
  padding: 10px;
  background: var(--light-orange-color);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-us-modal-divider {
  border: 1px solid grey;
  margin: 20px 0;
}

.contact-us-modal-footer {
  text-align: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}
