.user-address-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.user-address-text-orange {
  color: var(--orange-color) !important;
}

.user-address-icon-col {
  display: flex;
  margin-top: 0.3rem;
}

.user-address-add-new {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-left: 20px;
  cursor: pointer;
}

.user-address-text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user-address-container {
  padding: 20px;
}

.user-address-row {
  display: flex;
  gap: 20px;
}

.user-address-col {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  margin-bottom: 20px;
}
.user-address-card {
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.user-address-border-dark {
  border-color: #000;
}

.user-address-card-body {
  padding: 20px;
}

.user-address-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.user-address-card-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

.user-address-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: bold;
}
