.reservation-card-link {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  width: calc(33.333% - 15px);
}

.reservation-card {
  border-radius: 15px;
  padding: 20px;
  border: 1px solid var(--divider-color);
  cursor: pointer;
}

.reservation-title {
  font-weight: bold;
}

.reservation-detail {
  margin: 10px 0;
}

.label {
  color: #717171;
}

.value {
  font-weight: bold;
  color: black;
}

.reservation-divider {
  height: 1px;
  background-color: var(--divider-color);
  margin: 20px 0;
}

.vertical-divider {
  margin: 0 10px;
  border-left: 1px solid var(--divider-color);
  height: 20px;
  display: inline-block;
}

.reservation-footer {
  display: flex;
  align-items: center;
}

.planner-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.planner-name {
  font-weight: bold;
  margin-bottom: 0;
}

.planner-role {
  color: #717171;
  margin-bottom: 0;
}
