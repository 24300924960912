header {
  padding: 1em 7em 1.5em;
  display: flex;
  justify-content: space-between;
}

.gratif-logo-image {
  height: 3.75rem;
  cursor: pointer;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right-text {
  font-size: 1.1rem;
  margin-right: 2rem;
  cursor: pointer;
  line-height: 0;
  margin-bottom: 0;
}

.header-button {
  background-color: var(--orange-color);
  border: none;
  color: var(--black-color);
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {
  .onboard {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .gratif-logo-image {
    height: 3rem;
  }
}

@media screen and (max-width: 320px) {
  .header-right-text {
    font-size: 1rem;
  }
}
