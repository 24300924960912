.onboarding-header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 100px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.onboarding-header {
  display: flex;
}

.mobile-onboarding-container {
  display: none;
}

.onboarding-header-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-icon {
  background-color: #717171;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.breadcrumb-home-icon {
  padding: 5px;
  color: white;
  font-size: 18px;
  border-radius: 50%;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "-";
  margin-left: 8px;
  margin-right: 8px;
  font-size: 30px;
  line-height: 0;
  font-weight: 100;
}

.breadcrumb-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.breadcrumb-circle::before {
  content: "\2713";
  font-size: 20px;
  font-weight: bold;
  color: black;
  position: absolute;
  display: none;
}

.breadcrumb-active-circle {
  background-color: #3c8826;
}

.breadcrumb-active-circle::before {
  display: block;
  color: white;
}

.breadcrumb-inactive-circle {
  border: 1px solid #717171;
}

.separator {
  height: 2px;
  width: 20px;
  background-color: #000;
  margin-right: 8px;
}

.active-text {
  font-weight: bold;
}

.inactive-text {
  color: #717171;
}

.orange-button-onboard {
  background-color: var(--orange-color);
  border: none;
  color: var(--black-color);
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
}

.orange-button-onboard-mobile {
  background-color: var(--orange-color);
  border: none;
  color: var(--black-color);
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  width: 100px;
  margin-bottom: 20px;
}

@media (max-width: 1150px) {
  .onboarding-header-container {
    padding: 20px;
  }

  .orange-button-onboard {
    display: none;
  }

  .onboarding-header-container-mobile {
    width: 100%;
    padding: 20px;
  }

  .breadcrumb-circle {
    height: 18px;
    width: 18px;
  }

  .home-icon {
    padding: 2px 6px;
  }
}

@media (max-width: 767px) {
  .mobile-onboarding-container {
    display: block !important;
  }
  .desktop-onboarding-container {
    display: none !important;
  }
  .onboarding-header-container {
    justify-content: center;
  }
}

@media (max-width: 570px) {
  .active-text,
  .inactive-text {
    font-size: 13px;
  }
  .breadcrumb-circle {
    height: 16px;
    width: 16px;
  }
  .home-icon {
    padding: 2px 6px;
  }
  .breadcrumb-home-icon {
    font-size: 15px;
  }
}
