.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  text-align: center;
}

.option-item.selected {
  border-color: var(--orange-color);
}

.option-item img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  display: inline-block;
  margin: 0 5px;
}

.option-save-button {
  background-color: var(--light-orange-color);
  font-weight: bold;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.option-modal-title {
  text-align: center;
  width: 100%;
}

.option-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.option-name {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}
