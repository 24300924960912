@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --orange-color: #ff7f24;
  --light-orange-color: #febd59;
  --black-color: #000000;
  --divider-color: #dedede;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Manrope";
}

.fw-500 {
  font-weight: 500;
}

.divider {
  height: 2px;
  background-color: #d9d9d9;
  margin-top: 40px;
}

.heading {
  font-size: 22px;
  color: #717171;
}

.add-more {
  color: var(--orange-color);
  font-size: 18px;
  cursor: pointer;
}

.add-more i {
  font-style: normal;
}
