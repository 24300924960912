.logo {
  height: 3.75rem;
  cursor: pointer;
  margin-right: 4rem;
}
.new-header {
  padding: 0;
  box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.06);
}

.search-box {
  width: 25rem;
  border: 0.0625rem solid #ccc;
  border-radius: 2rem;
  padding: 0.3rem 1rem;
}

.search-box input {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.search-icon {
  margin-right: 0.5rem;
}

.btn-link {
  color: inherit;
  text-decoration: none;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
