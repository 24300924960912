.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 4rem;
  margin-bottom: 4rem;
  height: 3rem;
}

.search-bar input {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
}

.search-icon {
  color: #888;
  font-size: 20px;
}

.no-services {
  text-align: center;
  color: #888;
  font-size: 18px;
  margin-top: 2rem;
}
