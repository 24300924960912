.service-counter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.counter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.counter-list {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
}

.counter-item {
  width: 48px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.counter-item.selected {
  border-color: #ff7f00;
  background-color: #ffe5b4;
  font-weight: bold;
}

.discount-info {
  margin-top: 10px;
  font-size: 16px;
}
