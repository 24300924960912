.add-ons-card {
  border-radius: 5px;
  padding: 11px;
  border: 1px solid var(--divider-color);
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}

.add-ons-card .service-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.add-ons-card .service-subtitle {
  margin-bottom: 0;
  margin-right: 100px;
}

.add-ons-card .service-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--orange-color);
  border-radius: 50%;
  padding: 8px 13px;
  color: black;
}

.add-ons-card.selected {
  border: 1px solid black;
}

.add-ons-card.selected .service-icon {
  background-color: black;
  color: white;
}

.add-ons-card.selected .service-icon .fa-trash {
  display: block;
}

.add-ons-card .service-icon .fa-trash {
  display: none;
}
