.reservation-details-card {
  border-radius: 10px;
  padding: 25px;
  border: 1px solid var(--divider-color);
  background-color: white;
  margin-top: 20px;
}

.reservation-details-title {
  font-weight: bold;
}

.reservation-details-divider {
  height: 1px;
  background-color: var(--divider-color);
  margin: 20px 0;
}

.reservation-details-content {
  margin-bottom: 20px;
}

.reservation-details-text {
  margin: 10px 0;
}

.label {
  color: #717171;
}

.value {
  font-weight: bold;
  color: black;
}

.vertical-divider {
  margin: 0 10px;
  border-left: 1px solid var(--divider-color);
  height: 20px;
  display: inline-block;
}

.reservation-details-header {
  display: flex;
  justify-content: space-between;
}

.reservation-details-button {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
}

.reservation-link-button {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
}

.reservation-details-button svg {
  margin-right: 10px;
}

.reservation-details-text {
  display: flex;
  align-items: center;
}

.reservation-details-text .value {
  margin-left: 8px;
}

.reservation-details-main {
  display: flex;
  justify-content: space-between;
}

.reservation-details-left {
  flex: 2;
}

.reservation-details-right {
  flex: 1;
  padding-left: 20px;
}

.vertical-line {
  border-left: 1px solid var(--divider-color);
  margin: 0 20px;
}

.reservation-details-timeline {
  display: flex;
  align-items: center;
  margin: 30px 0;
  position: relative;
  width: 100%;
}

.stepper-container {
  width: 100%;
}

.reservation-details-right .reservation-details-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
