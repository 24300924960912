.home-container {
  padding: 1em 7em 1.5em;
}

.home-top-container {
  display: flex;
  height: 30rem;
}

.home-top-left,
.home-top-right {
  flex: 1;
}

.home-top-right {
  text-align: end;
  width: 50%;
}

.home-globe-image {
  height: 100%;
}

.home-top-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-top-left h1 {
  line-height: 1;
  font-weight: 100;
  font-weight: 600;
  font-size: 4rem;
}

.home-top-left p {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: start;
}

.home-join-button {
  background-color: var(--orange-color);
  border: none;
  color: var(--black-color);
  width: 25rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin: 4px 2px;
  cursor: pointer;
  padding: 12px 20px;
  border-radius: 5px;
}

.home-separator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-separator-image {
  height: 0.5rem;
}

.home-separator-h1 {
  font-size: 2rem;
  padding: 2rem;
  letter-spacing: 3px;
  margin-bottom: 0;
}

.home-join-img {
  height: 10rem;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 1rem;
}

.circle::before {
  content: "\2713";
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.circle.last-circle {
  background-color: black;
}

.circle.last-circle::before {
  color: white;
}

.line {
  width: 2px;
  height: 22px;
  background-color: black;
  margin-left: 18px;
}

.last-line {
  width: 2px;
  height: 25px;
  background-color: black;
  margin-left: 18px;
}
/* .line {
  width: 2px;
  height: 50px;
  background-color: black;
  margin-left: 18px;
} */

.text {
  margin-top: 10px;
  text-align: center;
}

.circle-text-row {
  display: flex;
  align-items: center;
}

.work-container {
  display: flex;
  height: 30rem;
}

.work-left-side-img {
  width: 400px;
  margin-right: 20px;
}

.work-left-side,
.work-right-side {
  flex: 1;
}

.work-right-side {
  align-self: start;
  justify-content: center;
  display: flex;
  height: 300px;
}

.faq-right-side {
  width: 100%;
}

.faq-container {
  margin-right: 30px;
  padding: 20px;
}

.faq-title {
  font-size: 3em;
  font-weight: 700;
}

.faq-text {
  margin-bottom: 0;
  padding-bottom: 0;
}

.bold-email {
  font-weight: bold;
}

.faq-image {
  margin-top: 20px;
  margin-left: 20px;
  width: 12rem;
}

.join-p {
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .home-top-left h1 {
    font-size: 2rem;
  }

  .home-top-left h1:nth-child(2) {
    font-size: 2rem;
  }

  .home-top-left p {
    font-size: 1rem;
  }

  .home-join-button {
    width: 16rem !important;
  }

  .home-top-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-globe-image {
    height: 60%;
  }

  .home-separator-h1 {
    font-size: 1.5rem !important;
  }

  .work-left-side-img {
    width: 23.5rem;
  }

  .last-line {
    height: 45px;
  }

  /* .line-small {
    width: 2px;
    height: 22px;
    background-color: black;
    margin-left: 18px;
  } */
}

@media screen and (max-width: 767px) {
  .home-top-container {
    flex-direction: column-reverse;
  }

  .home-globe-image {
    height: 15rem;
  }

  .home-top-left {
    align-items: center;
  }

  .home-join-button {
    width: 100% !important;
  }

  .home-separator-image {
    width: 100px;
  }

  .work-left-side-img {
    margin-bottom: 20px;
  }

  .home-separator-h1 {
    font-size: 1.5rem;
    padding: 0 10px;
  }

  .home-separator {
    margin: 20px 0;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .home-join-img {
    height: 8rem;
  }

  .last-line {
    height: 22px;
  }
}

@media screen and (max-width: 375px) {
  .home-separator-image {
    width: 5rem;
  }

  .work-left-side-img {
    width: 18rem;
  }

  .home-separator-h1 {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 320px) {
  .work-left-side-img {
    width: 18rem;
  }

  .home-separator-image {
    width: 70px;
  }

  .home-join-img {
    height: 6rem;
  }
}
