.auth-page {
  display: flex;
  height: 100vh;
}

.auth-left-side {
  width: 50%;
}

.auth-right-side {
  margin-top: 20px;
  padding: 0 10rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.auth-right-side h2 {
  font-weight: bold;
}

.auth-right-side input {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #dedede;
}

.auth-right-side p {
  margin: 10px 0;
}

.auth-right-side p:nth-of-type(1) {
  color: #717171;
}

.auth-right-side p:nth-of-type(2) a {
  color: #194cc3;
  text-decoration: underline;
  font-weight: bold;
}

.auth-continue-button {
  margin-top: 20px;
}

.auth-otp-input {
  display: flex;
  justify-content: space-between;
}

.auth-otp-input input {
  height: 5rem;
  width: 5rem;
  text-align: center;
  border: 1px solid #dedede;
  margin-right: 10px;
}

.auth-otp-input input:last-child {
  margin-right: 0;
}

.otp-instruction {
  font-weight: 500;
}

.resend-text {
  font-weight: 500;
}

.resend-link {
  font-weight: bold;
  text-decoration: underline;
}

.google-continue-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 12px 10px;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 25px;
  font-weight: 600;
  background-color: white;
}

.google-icon {
  height: 1.5rem;
  margin-right: 20px;
}

.auth-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
}

.auth-divider-line {
  flex-grow: 1;
  height: 1px;
  background-color: #e7e7e7;
}

.auth-divider-text {
  margin: 0 20px;
  font-weight: bold;
  color: #676767;
}
