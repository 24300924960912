.service-list-header {
  display: flex;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
}

.service-list-chevron-icon {
  margin-right: 1rem;
}

.service-list-title {
  margin: 0;
}
